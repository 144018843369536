
    .wrapper {
      border-bottom: 1px solid black;
      overflow: hidden;
    }
    
    .wrapper .question-container {
      width: 100%;
      text-align: left;
      padding: 20px 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-weight: 500;
      font-size: 20px;
      background: transparent;
      border: none;
      cursor: pointer;
      font-family: "Source Sans Pro", sans-serif;
    }
    
    .question-container.active {
      color: #1db954;
      background-image: linear-gradient(90deg,transparent,rgba(0,0,0,0.04),transparent);
    }
    
    .wrapper .question-container:hover {
      background-image: linear-gradient(90deg,transparent,rgba(0,0,0,0.04),transparent);
    }
    
    .wrapper .arrow {
      font-size: 2rem;
      transition: .5s ease-in-out;
    }
    
    .arrow.active {
      rotate: 180deg;
      color: #1db954;
    }
    
    .wrapper .answer-container {
      padding: 0 1rem;
      transition: .7s ease-in-out;
      
    }
    
    .wrapper .answer-content {
      padding: 1rem 0;
      font-size: 20px;
      margin-left: -30px;
      font-family: "Source Sans Pro", sans-serif;
    }
    
    .details-list {
      margin-top: 5px;
    }
    .location-img{
     padding: 5px;
    }
