.blog__item {
  border: 1px solid #7c8a972e;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}

.blog__time {
  border-top: 1px solid #7c8a972e;
}

.read__more {
  color: #f9a826;
  font-weight: 600;
  text-decoration: none;
  font-size: 1rem;
}

.read__more:hover {
  color: #f9a826;
}



.book__now{
  display: inline-block;
  margin-top: 1rem;
 background-color: #f9a826;
  color:#fff;
  padding:.1rem 1rem;
  cursor: pointer;
  font-size: 1.5rem;
  text-decoration: none;
  border-radius: 5px;
}

.book__now:hover{
  background:rgba(255, 165, 0,.2);
  
}

/* .section__description i {
  margin-top: 5px;
} */

.blog__time span i {
  color: #000000ba;
  
}

.destination__pickup, .destination__cancelation {
  display: flex;
  justify-content: space-between;
  color: #000d6b;
  font-weight: 400;
  font-size: 17px;
}

 .destination__price {
  font-weight: 600;
  font-size: 22px;
  display: flex;
  justify-content: space-between;
  color: #000d6b;
}

.destination__price i{
  font-weight: 600;

}

.blog__title {
  text-decoration: none;
  color: #000d6b;
  font-size: 1.2rem;
  font-weight: 600;
  transition: 0.3s;
}

.blog__title:hover {
  color: #f9a826;
}
b {
  margin-left: 5px;
  
}

@media only screen and (max-width: 768px) {
  .blog__time {
    padding-top: 5px !important;
  }

  .blog__time span {
    font-size: 0.7rem;
  }

  .blog__info p {
    font-size: 0.7rem;
    line-height: 1.5rem;
    margin-top: 10px;
  }

  .blog__title {
    font-size: 1.1rem;
  }

  .read__more {
    font-size: 0.7rem;
  }
}

@media only screen and (max-device-width: 480px) {
  .blog__info p {
      margin-top: -5px;
    }

}
