.blog__publisher span i {
  color: #f9a826;
}
.blog__publisher{
  flex-wrap: wrap;
}
blockquote {
  line-height: 35px;
}

.single__comment img {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  border: 2px solid #000d6b;
  object-fit: cover;
}

.replay i {
  color: #f9a826;
}

.replay {
  color: #000d6b;
  font-weight: 600;
  cursor: pointer;
}

.leave__comment-form input,
.leave__comment-form textarea {
  border: 1px solid rgba(0, 12, 107, 0.118);
}

.leave__comment-form textarea:focus {
  outline: none;
}

.comment__btn {
  background: #000d6b !important;
  color: #fff !important;
}

.recent__blog-item h6 a {
  text-decoration: none;
  color: #000d6b;
  font-weight: 600;
}
.cancelation-button {
  margin-left: 29px;
  text-decoration: none;
}

.destination__price{
  font-size: 17px;
  color: #f9a826;
}
.location-img {
  width: 470px;
  height: 470px;
}
@media only screen and (max-width: 768px) {
  .recent__post {
    margin-top: 30px;
  }

  .leave__comment-form input,
  .leave__comment-form textarea {
    font-size: 0.7rem;
  }

  .comment__btn {
    padding: 5px 10px;
    font-size: 0.8rem;
    margin-top: 0px !important;
  }

  .leave__comment-form h4 {
    font-size: 1.2rem;
  }

  .single__comment img {
    width: 40px;
    height: 40px;
  }

  .comment__content p {
    font-size: 0.8rem;
    line-height: 1.4rem;
  }

  .blog__details p {
    font-size: 0.8rem;
    line-height: 1.4rem;
  }
}

@media only screen and (max-device-width: 480px) {
      .blog__publisher {
        flex-wrap: wrap;
        }
      iframe{
        width: 345px;
        height: 300px;
      }
            .location-img {
              width: 370px;
              height: 330px;
            }
                        .cancelation-button {
                          font-size: 15px;
                          margin-left: 30px;
                          text-decoration: none;
                        }
}
